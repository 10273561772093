import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { Footer, LogoGrid } from "../../components";

export const Event: React.FC = () => {
  const schedule = {
    "July 28th": {
      "8:00 AM CT": {
        restrictions: "AUNT Members Only",
        title: "Breakfast is served",
        location: undefined,
        speaker: undefined,
      },
      "9:00 AM CT": {
        restrictions: "AUNT Members Only",
        title: "Introductions",
        location: "Deer/Elk Lake",
        speaker: undefined,
      },
      "9:15 AM CT": {
        restrictions: "AUNT Members Only",
        title: "Transformative Therapy Centers for Cell and Gene Therapy",
        location: "Deer/Elk Lake",
        speaker: undefined,
      },
      "10:00 AM CT": {
        restrictions: "AUNT Members Only",
        title: "Study Results Review",
        location: "Deer/Elk Lake",
        speaker: undefined,
      },
      "10:45 AM CT": {
        restrictions: "AUNT Members Only",
        title: "Break",
        location: undefined,
        speaker: undefined,
      },
      "11:00 AM CT": {
        restrictions: "AUNT Members Only",
        title: "Study Specifics and Discussion",
        location: "Deer/Elk Lake",
        speaker: undefined,
      },
      "12:00 PM CT": {
        restrictions: "Open to All",
        title: "Lunch",
        location: "Ballroom Floor",
        speaker: undefined,
      },
      "1:00 PM CT": {
        restrictions: "Open to All",
        title: "Where are We Now?",
        speaker: "Dr. Julie Kanter",
        location: "Ballroom 1&2",
      },
      "2:00 PM CT": {
        restrictions: "Open to All",
        title: "Review of 2022-2023 NASCC Consensus Recommendations",
        speaker: "Multiple",
        location: "Ballroom 1&2",
      },
      "3:00 PM CT": {
        restrictions: "Open to All",
        title: "This Year’s Meeting",
        speaker: "Dr. Julie Kanter",
        location: "Ballroom 1&2",
      },
      "4:00 PM CT": {
        restrictions: "Open to All",
        title: "Committee Meetings",
        location: "Various Breakout Rooms",
        speaker: undefined,
      },
      "6:00 PM CT": {
        restrictions: "Open to All",
        title: "Opening Reception at Hotel",
        location: "Ballroom Floor",
        speaker: undefined,
      },
    },
    "July 29th": {
      "9:00 AM CT": {
        restrictions: "Open to All",
        title: "The Importance of the Annual Visit",
        speaker: "Dr. Sophie Lanzkron",
        location: "Ballroom 1&2",
      },
      "10:00 AM CT": {
        restrictions: "Open to All",
        title: "What is Palliative Care?",
        speaker: "Dr. Rodney Turner",
        location: "Ballroom 1&2",
      },
      "11:00 AM CT": {
        restrictions: "Open to All",
        title: "Transformative Therapy Centers for Cell and Gene Therapy",
        speaker: "Dr. Julie Kanter",
        location: "Ballroom 1&2",
      },
      "12:00 PM CT": {
        restrictions: "Open to All",
        title: "Lunch",
        location: "Ballroom Floor",
        speaker: undefined,
      },
      "1:00 PM CT - Ballroom 3&4": {
        restrictions: "NASCC Members Only",
        title: "Palliative Care in Sickle Cell Disease",
        location: "Ballroom 3&4",
        speaker: undefined,
      },
      "1:00 PM CT - Deer/Elk Lake": {
        restrictions: "NASCC Members Only",
        title: "GT Discussions about the 'Right' Patient",
        location: "Deer/Elk Lake",
        speaker: undefined,
      },
      "1:00 PM CT - Birch/Maple": {
        restrictions: "NASCC Members Only",
        title: "GRNDaD PI and Coordinator Meeting",
        location: "Birch/Maple",
        speaker: undefined,
      },
      "1:00 PM CT - Pine/Cedar": {
        restrictions: "NASCC Members Only",
        title: "Neurocognitive Workgroup",
        location: "Pine/Cedar",
        speaker: undefined,
      },
    },
    "Jul 30, 2024": {
      "9:00 AM CT": {
        restrictions: "Open to All",
        title: "Cancer Treatment in Specialized Populations",
        speaker: "Dr. Thomas S. Uldrick",
        location: "Ballroom 1&2",
      },
      "10:00 AM CT": {
        restrictions: "Open to All",
        title: "Improvising Transition Documentation",
        speaker: "Dr. Stephanie Guarino",
        location: "Ballroom 1&2",
      },
      "11:00 AM CT": {
        restrictions: "Open to All",
        title: "Optimizing SCD and CBO Collaboration",
        speaker: "Dr. Raymona Lawrence",
        location: "Ballroom 1&2",
      },
      "12:00 PM CT": {
        restrictions: "Open to All",
        title: "Lunch",
        location: "Ballroom Floor",
        speaker: undefined,
      },
      "12:00 PM CT - Pfizer Theatre": {
        restrictions: "Open to All",
        title: "Product Theatre by Pfizer with Lunch Provided",
        location: "Ballroom 3&4",
        speaker: undefined,
      },
      "1:00 PM CT - Ballroom 3&4": {
        restrictions: "NASCC Members Only",
        title: "Cancer Care in Sickle Cell Disease",
        location: "Ballroom 3&4",
        speaker: undefined,
      },
      "1:00 PM CT - Deer/Elk Lake": {
        restrictions: "NASCC Members Only",
        title: "Community Based Organization Consensus Working Group",
        location: "Deer/Elk Lake",
        speaker: undefined,
      },
      "1:00 PM CT - Birch/Maple": {
        restrictions: "NASCC Members Only",
        title: "SAPPORT Meeting",
        location: "Birch/Maple",
        speaker: undefined,
      },
      "1:00 PM CT - Pine/Cedar": {
        restrictions: "NASCC Members Only",
        title: "Neurocognitive Workgroup",
        location: "Pine/Cedar",
        speaker: undefined,
      },
      "6:00 PM CT": {
        restrictions: "Open to All",
        title: "Exhibitor Night at Pryes Brewery",
        location: "Pryes Brewery",
        speaker: undefined,
      },
    },
    "Jul 31, 2024": {
      "9:00 AM CT - Clinician Track": {
        restrictions: "Open to All",
        title: "Clinician Track: How to Implement Infusion Centers for SCD",
        speaker: "Dr. Sophie Lanzkron",
        location: "Ballroom 1&2",
      },
      "10:00 AM CT - Clinician Track": {
        restrictions: "Open to All",
        title: "Clinician Track: Building and Implementing Pain Plans for SCD",
        speaker: "Dr. C. Patrick Carroll",
        location: "Ballroom 1&2",
      },
      "11:00 AM CT - Clinician Track": {
        restrictions: "Open to All",
        title: "Clinician Track: Integrating Primary Care in Your SCD Center",
        speaker: "Dr. Rachel Klein",
        location: "Ballroom 1&2",
      },
      "9:00 AM CT - Social Work Track": {
        restrictions: "Open to All",
        title:
          "Social Work Track: What is a Sickle Cell Disease Social Worker?",
        speaker: "Spencer Moorman",
        location: "Ballroom 3&4",
      },
      "10:00 AM CT - Social Work Track": {
        restrictions: "Open to All",
        title: "Social Work Track: Motivational Interviewing",
        speaker: "Caryn R. Rodgers",
        location: "Ballroom 3&4",
      },
      "11:00 AM CT - Social Work Track": {
        restrictions: "Open to All",
        title: "Social Work Track: Care Coordination for SCD",
        speaker: "Charlene Sylvestre",
        location: "Ballroom 3&4",
      },
      "12:00 PM CT": {
        restrictions: "Open to All",
        title: "Lunch",
        location: "Ballroom Floor",
        speaker: undefined,
      },
      "12:00 PM CT - Novo Nordisk Scientific Session": {
        restrictions: "Open to All",
        title: "Scientific Session by Novo Nordisk",
        location: "Ballroom 3&4",
        speaker: undefined,
      },
      "1:00 PM CT - Ballroom 3&4": {
        restrictions: "Non Medical Industry Members Only",
        title:
          "Continued Consensus Inpatient Management of Adults with Sickle Cell Disease",
        location: "Ballroom 3&4",
        speaker: undefined,
      },
      "1:00 PM CT - Deer/Elk Lake": {
        restrictions: "Non Medical Industry Members Only",
        title:
          "Working Group: Improving Quality of TCD as the Sickle Cell Screen",
        location: "Deer/Elk Lake",
        speaker: undefined,
      },
      "1:00 PM CT - Birch/Maple": {
        restrictions: "NASCC Members Only",
        title: "GT Discussions about the 'Right' Patient - if needed",
        location: "Birch/Maple",
        speaker: undefined,
      },
      "1:00 PM CT - Pine/Cedar": {
        restrictions: "NASCC Members Only",
        title: "Social Workers Consensus for Best Practices",
        location: "Pine/Cedar",
        speaker: undefined,
      },
    },
    "Aug 1, 2024": {
      "9:00 AM CT": {
        restrictions: "Open to All",
        title: "Models of Successful SCD Centers",
        speaker: "Multiple",
        location: undefined,
      },
      "10:00 AM CT": {
        restrictions: "Open to All",
        title: "How to Use a Pharmacist to Improve SCD Care?",
        speaker: "Dr. Jeremy Osborn",
        location: undefined,
      },
      "11:00 AM CT": {
        restrictions: "Open to All",
        title: "Understanding Your SCC as a Business",
        speaker: "Latha Sudhakar, MS, PMP",
        location: undefined,
      },
      "12:00 PM CT": {
        restrictions: "Open to All",
        title: "Lunch",
        location: "Ballroom Floor",
        speaker: undefined,
      },
      "1:00 PM CT": {
        restrictions: "NASCC Members Only",
        title: "Consensus Group Recap",
        location: "Ballroom 1&2",
        speaker: undefined,
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>NASCC - Annual Meeting and Consensus</title>
        <meta
          name="description"
          content="Information about the NASCC Annual Meeting and Consensus, including the schedule, location, and topics."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <main className="static-page-main">
        <h2 className="static-page-header">
          NASCC Annual Meeting and Consensus!
        </h2>
        <section className="relative bg-white shadow-lg p-2 sm:p-4 md:rounded-lg md:p-6">
          <div className="prose-text">
            NASCC is hosting it's annual consensus conference in Minneapolis,
            Minnesota from July 28th to August 1st. This year, the conference is
            open to not only member center staff, but community based
            organizations, governmental employees, and medical industry
            representatives. As always, the NASCC Annual Meeting and Consensus
            is free for members!
            <br />
            <br />
            <strong>Note:</strong> we will be adding to this page as details are
            finalized!
          </div>
        </section>
        <h2 className="static-page-header">Why?</h2>
        <section className="relative bg-white shadow-lg p-2 sm:p-4 md:rounded-lg md:p-6">
          <div className="prose-text">
            NASCC is a non-profit organization founded to enhance the quality of
            care provided to individuals with SCD through dedicated
            organizational support and quality improvement. Our annual consensus
            meetings are a key part of our mission to provide the best
            information on treatments, techniques, and knowledge to sickle cell
            centers centers around the nation. We are excited to have you join
            us for this year's conference!
          </div>
        </section>
        <h2 className="static-page-header">Where?</h2>
        <section className="relative bg-white shadow-lg p-2 sm:p-4 md:rounded-lg md:p-6">
          <div className="prose-text">
            <a
              href="https://www.marriott.com/en-us/hotels/mspcc-minneapolis-marriott-city-center/overview/"
              className="hover:text-blue-800"
            >
              Minneapolis Marriott City Center, Minneapolis, Minnesota
            </a>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11289.309385477121!2d-93.2735476!3d44.9776609!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52b33290dd2e88ed%3A0x9d6e304d23b325b6!2sMinneapolis%20Marriott%20City%20Center!5e0!3m2!1sen!2sus!4v1712334238807!5m2!1sen!2sus"
              width="1080"
              height="720"
              className="mt-6"
              allowFullScreen={false}
              loading="lazy"
              title="The Minneapolis Marriot City Center"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </section>
        <h2 className="static-page-header">What?</h2>
        <section className="relative bg-white shadow-lg p-2 sm:p-4 md:rounded-lg md:p-6">
          <div className="prose-text">
            The goal of this meeting is to develop white papers (consensus
            recommendations) for sickle cell disease management in areas where
            the current guidelines remain nebulous (i.e. not enough data for
            GRADE guidelines), or guidelines from differing groups needed to be
            put together into a more readable/actionable way, or there just are
            not any current guidelines. These will be consensus recommendations
            that can be tested as part of our QI efforts within the Alliance.
            <br />
            <br />
            Some of the meetings will be open to anyone who registers, while
            others will be limited to NASCC members only. Primarily, the limited
            meetings will focus on actual consensus recommendations. However, we
            will have a variety of great speakers and interesting topics that
            are open to anyone in the Who section below!
            <br />
            <br />
            <strong>A Non-comprehensive List of Topics:</strong>
            <ol>
              <li>Transformative Therapy Centers for Cell and Gene Therapy</li>
              <li>Palliative Care for Sickle Cell Disease</li>
              <li>Cancer Care for People with Sickle Cell Disease</li>
              <li>Electronic Health Record Transitions</li>
              <li>Models of a Successful SCD Center</li>
              <li>Building and Implementing Pain Plans</li>
              <li>And More!</li>
            </ol>
          </div>
        </section>
        <h2 className="static-page-header">Who?</h2>
        <section className="relative bg-white shadow-lg p-2 sm:p-4 md:rounded-lg md:p-6">
          <div className="prose-text">
            NASCC member center representatives (full centers and associate
            centers), medical industry personnel, community based organization
            representatives, and governmental employees are all welcome to
            attend. For non-NASCC members, please use{" "}
            <a
              href="https://nascc.regfox.com/national-alliance-of-sickle-cell-centers-consensus-2024-non-members"
              className="link-text"
            >
              this link to register
            </a>
            .
            <br />
            <br />
            Members please email{" "}
            <a
              href="mailto:consensus@sicklecellcenters.org"
              className="link-text"
            >
              consensus@sicklecellcenters.org
            </a>{" "}
            for our members only webpage link, where you'll be able to register
            - tickets are free for participating NASCC member center
            representatives! When sending your email, please indicate which
            center you are associated with.
          </div>
        </section>
        <h2 className="static-page-header">Sponsors</h2>
        <section className="relative bg-white shadow-lg p-2 sm:p-4 md:rounded-lg md:p-6">
          <div className="prose-text">
            <p>
              We would like to thank our sponsors for their support in making
              this event possible. If you are interested in becoming a sponsor,
              please reach out to{" "}
              <a
                href="mailto:sponsors@sicklecellcenters.org"
                className="link-text"
              >
                sponsors@sicklecellcenters.org
              </a>
              . You'll be able to have your logo and profile featured on our
              site, as well as play an important part in providing the resources
              to allow NASCC to shape the future of sickle cell centers around
              the nation.
            </p>
            <div className="flex flex-col">
              <h3 className="text-4xl font-bold self-center">
                Platinum Sponsors
              </h3>
              <LogoGrid sponsorshipType="platinum" />
            </div>
            <div className="flex flex-col">
              <h3 className="text-4xl font-bold self-center">Gold Sponsors</h3>
              <LogoGrid sponsorshipType="gold" />
            </div>
          </div>
        </section>
        <h2 className="static-page-header">Schedule</h2>
        <section className="relative bg-white shadow-lg p-2 sm:p-4 md:rounded-lg md:p-6">
          <div className="prose-text">
            <ul>
              {Object.entries(schedule).map(([date, events]) => (
                <li key={date}>
                  <h3>{date}</h3>
                  <ul>
                    {Object.entries(events).map(([time, event]) => (
                      <li key={time}>
                        <h4>{time}</h4>
                        <ul>
                          <li>
                            <strong>{event.title}</strong>
                          </li>
                          {event.speaker && <li>Speaker: {event.speaker}</li>}
                          {event.location && (
                            <li>Location: {event.location}</li>
                          )}
                          {event.restrictions && (
                            <li>
                              <strong>Restrictions:</strong>{" "}
                              {event.restrictions}
                            </li>
                          )}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        </section>
        <h2 className="static-page-header">
          NASCC Accreditation & Disclosure Information
        </h2>
        <section className="relative bg-white shadow-lg p-2 sm:p-4 md:rounded-lg md:p-6">
          <div className="prose-text">
            <h3 className="text-2xl font-bold -mt-4">
              Accreditation Statement
            </h3>
            <p>
              In support of improving patient care, this activity has been
              planned and implemented by The France Foundation and the National
              Alliance of Sickle Cell Centers. The France Foundation is jointly
              accredited by the Accreditation Council for Continuing Medical
              Education (ACCME), the Accreditation Council for Pharmacy
              Education (ACPE), and the American Nurses Credentialing Center
              (ANCC), to provide continuing education for the healthcare team.
            </p>
          </div>
          <h3 className="text-2xl font-bold">Physician Credit Designation</h3>
          <div className="prose-text">
            <p>
              The France Foundation designates this live activity for a maximum
              of 10.0 AMA PRA Category 1 Credit(s)™. Physicians should claim
              only the credit commensurate with the extent of their
              participation in the activity.
            </p>
          </div>
          <h3 className="text-2xl font-bold">Nurses Credit Designation</h3>
          <div className="prose-text">
            <p>
              The France Foundation designates this activity for 10.0 contact
              hours.
            </p>
          </div>
          <h3 className="text-2xl font-bold">
            Physician Assistant Credit Designation
          </h3>
          <div className="prose-text">
            <p>
              The France Foundation has been authorized by the American Academy
              of PAs (AAPA) to award AAPA Category 1 CME credit for activities
              planned in accordance with AAPA CME Criteria. This activity is
              designated for 10.0 AAPA Category 1 CME credits. PAs should only
              claim credit commensurate with the extent of their participation.
            </p>
          </div>
          <h3 className="text-2xl font-bold">
            Social Workers Credit Designation
          </h3>
          <div className="prose-text">
            <p>
              As a Jointly Accredited Organization, The France Foundation is
              approved to offer social work continuing education by the
              Association of Social Work Boards (ASWB) Approved Continuing
              Education (ACE) program. Organizations, not individual courses,
              are approved under this program. Regulatory boards are the final
              authority on courses accepted for continuing education credit.
              Social workers completing this course receive 10.0 clinical
              continuing education credits.
            </p>
          </div>
          <h3 className="text-2xl font-bold">Pharmacists Credit Designation</h3>
          <div className="prose-text">
            <p>
              This knowledge and application-based activity qualifies for 10.0
              contact hours (0.10 CEUs) of continuing pharmacy education credit.
              <strong>
                Please submit all evaluations and credit requests no later than
                30 days after you complete this activity to ensure your credit
                fulfillment, as CE credit cannot be awarded past 60 days from
                the activity date.
              </strong>
              {""}
              Your CE credits will be electronically submitted to the NABP upon
              successful completion of the activity. Pharmacists with questions
              can contact NABP customer service (custserv@nabp.net).
            </p>
          </div>
          <div className="prose-text">
            <p>
              All other healthcare professionals completing this course will be
              issued a statement of participation.
            </p>
          </div>
          <h3 className="text-2xl font-bold">Disclosure Statement</h3>
          <div className="prose-text">
            <p>
              The France Foundation (TFF) and NASCC require that individuals in
              a position to control the content of an educational activity
              disclose all relevant financial relationships with any commercial
              interest. TFF and NASCC resolve all conflicts of interest to
              ensure independence, objectivity, balance, and scientific rigor in
              all their educational programs. Furthermore, TFF and NASCC seek to
              verify that all scientific research referred to, reported, or used
              in a CE activity conforms to the generally accepted standards of
              experimental design, data collection, and analysis. TFF and NASCC
              are committed to providing learners with high-quality CE
              activities that promote improvements in health care and not those
              of a commercial interest. Disclosures for members of the planning
              committee and faculty will be disclosed to learners prior to the
              conference.
            </p>
          </div>
          <div className="prose-text">
            <p>You can download the disclosure form here: </p>
            <Link
              to="https://www.dropbox.com/scl/fi/o8a1cl1t71d39uh1l9hq4/Consensus2024-Disclosures.pdf?rlkey=6p2oa5r79clcorqlqqmqxwpu9&st=res8ozz2&dl=0"
              className="link-text"
            >
              Disclosure Statement
            </Link>
          </div>
        </section>
      </main>
      <Footer dataTestId="footer_8f99d498-ee13-44d2-91fc-38ff0962d944" />
    </>
  );
};
