/** @module Containers */
import React from "react";
import { Toaster } from "react-hot-toast";
import { Outlet, ScrollRestoration } from "react-router-dom";

import { NavHeader } from "../NavHeader/NavHeader";
import { Banner } from "../../components";

export const App: React.FC = () => {
  // Hide banner element on button click
  const [isBannerVisible, setIsBannerVisible] = React.useState(true);

  return (
    <>
      <ScrollRestoration />
      <div className="bg-gradient-to-b from-gray-100 to-white min-w-min overflow-y-hidden">
        <div
          className="w-full h-full overflow-y-scroll"
          data-testid="header-parent-container"
        >
          <Toaster
            containerStyle={{
              zIndex: 99999999,
              top: "20%",
            }}
          />
          {isBannerVisible && (
            <Banner dismissClick={() => setIsBannerVisible(false)} />
          )}
          <NavHeader data-testid="header-component" />
          <Outlet />
        </div>
      </div>
    </>
  );
};
