import React from "react";
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Integrations } from "@sentry/tracing";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { Provider } from "react-redux";

import * as serviceWorker from "./serviceWorker";
import "./styles/tailwind.css";
import {
  App,
  ApplicationDetail,
  ApplicationForm,
  ApprovedApplications,
  ConsensusRecommendations,
  ContactListDetails,
  ContactLists,
  EducationalResources,
  Error,
  Event,
  EventCalendar,
  ForgotPassword,
  JobList,
  JobPost,
  Home,
  MemberCenters,
  MemberDashboard,
  MembersHome,
  NewJobPost,
  ProviderResources,
  PendingApplications,
  Report,
  ResetPassword,
  SubscriberList,
  ConsensusRecommendationAdult,
  ConsensusRecommendationPediatric,
  ConsensusRecommendationInfant,
  PageNotFound,
  Grndad,
} from "./containers";
import store from "./redux/store";
import {
  AboutUs,
  CenterCriteria,
  CenterJoin,
  ConflictOfInterest,
  ContactUs,
  ExecutiveCommittee,
  Join,
  Login,
  LogoUsageGuidelines,
  OurPartners,
  Publications,
  SponsorPage,
  StartCenterInfo,
} from "./components";
import { ConsensusRecommendationGeneTherapy } from "./containers/ConsensusRecommendationGeneTherapy/ConsensusRecommendationGeneTherapy";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_RUN_ENV || "development",
});

const persistor = persistStore(store);

const container = document.getElementById("root");

const root = createRoot(container!);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<App />}>
        <Route index element={<Home />} />
        <Route path="about_us" element={<AboutUs />} />
        <Route
          path="associate_centers"
          element={<MemberCenters onlyAssociate={true} />}
        />
        <Route path="center_criteria" element={<CenterCriteria />} />
        <Route path="conflict_of_interest" element={<ConflictOfInterest />} />
        <Route path="consensus_meeting" element={<Event />} />
        <Route path="consensus_recommendations">
          <Route path="general" element={<ConsensusRecommendations />} />
          <Route path="adult" element={<ConsensusRecommendationAdult />} />
          <Route
            path="pediatric"
            element={<ConsensusRecommendationPediatric />}
          />
          <Route path="infant" element={<ConsensusRecommendationInfant />} />
          <Route
            path="gene_therapy_transformative_centers"
            element={<ConsensusRecommendationGeneTherapy />}
          />
        </Route>
        <Route path="grndad" element={<Grndad />} />
        <Route path="logo_guidelines" element={<LogoUsageGuidelines />} />
        <Route
          path="member_centers"
          element={<MemberCenters onlyAssociate={false} />}
        />
        <Route path="our_partners" element={<OurPartners />} />
        <Route path="start_a_center" element={<StartCenterInfo />} />
        <Route path="sponsors" element={<SponsorPage />} />
        <Route path="join" element={<Join />} />
        <Route path="center_join" element={<CenterJoin />} />
        <Route path="contact_info" element={<ContactUs />} />
        <Route path="publications" element={<Publications />} />
        <Route path="jobs" element={<JobList />} />
        <Route path="job/:jobId" element={<JobPost />} />
        <Route path="/create_new_job_post/:token" element={<NewJobPost />} />
        <Route
          path="educational_resources"
          element={<EducationalResources />}
        />
        <Route path="provider_resources" element={<ProviderResources />} />
        <Route path="member_login" element={<Login />} />
        <Route path="executive_committee" element={<ExecutiveCommittee />} />
        <Route
          path="center_application/:applicationType/:applicationToken"
          element={<ApplicationForm />}
        />
        <Route path="forgot_password" element={<ForgotPassword />} />
        <Route path="reset/:token" element={<ResetPassword />} />
      </Route>

      {/* Members Area */}
      <Route path="/members" element={<MemberDashboard />}>
        <Route path="dashboard" element={<MembersHome />} />
        <Route path="calendar" element={<EventCalendar />} />
        <Route path="pending_applications" element={<PendingApplications />} />
        <Route
          path="approved_applications"
          element={<ApprovedApplications />}
        />
        <Route
          path="center_application/:centerApplicationId"
          element={<ApplicationDetail />}
        />
        <Route path="contact_lists" element={<ContactLists />} />
        <Route
          path="contact_list/:contactListId"
          element={<ContactListDetails />}
        />
        <Route path="subscribers_list" element={<SubscriberList />} />
        <Route path="reports" element={<Report />} />
      </Route>
      <Route path="/error" element={<Error />} />
      <Route path="*" element={<PageNotFound />} />
    </Route>,
  ),
);

root.render(
  <Provider store={store}>
    <HelmetProvider>
      {/* @ts-ignore */}
      <PersistGate loading={null} persistor={persistor}>
        {/* @ts-ignore */}
        <Sentry.ErrorBoundary>
          <RouterProvider router={router} />
        </Sentry.ErrorBoundary>
      </PersistGate>
    </HelmetProvider>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
