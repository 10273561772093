import React from "react";
import { Link } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { useGetProviderResourceListQuery } from "../../services/providerResource.service";

export const ProviderResourcesDisplay: React.FC = () => {
  const [displayProviderResourceType, setDisplayProviderResourceType] =
    React.useState<string>("all");

  const {
    data: providerResources,
    isFetching,
    isLoading,
    isError,
    error,
  } = useGetProviderResourceListQuery({ offset: 0, limit: 100 });

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  console.log(providerResources);

  return (
    <section className="static-page-section">
      <div>
        <p className="text-xl text-gray-500 lg:text-2xl">
          Here you'll find links and information about resources you can use as
          a sickle cell care provider. Please{" "}
          <Link to="/contact_info" className="link-text">
            contact us
          </Link>{" "}
          if you'd like to get another resource added, or if you have a resource
          you'd like to recommend.
        </p>
      </div>
      {(isFetching || isLoading) && (
        <h2 className="static-page-header mt-10">Fetching publications...</h2>
      )}
      {!(isFetching && isLoading) && providerResources && (
        <div className="grid gap-16 pt-12 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
          {providerResources
            .filter((resource) =>
              displayProviderResourceType === "all"
                ? resource
                : resource.tags
                    .map((tag) => tag.color)
                    .includes(displayProviderResourceType),
            )
            .map((resource) => {
              return (
                <div key={resource.name}>
                  <div className="flex flex-wrap grow-0 space-x-0.5">
                    {resource.tags.map((tag) => (
                      <div key={tag.name} className="ml-0">
                        <button
                          onClick={() =>
                            setDisplayProviderResourceType(tag.color)
                          }
                          className="inline-block"
                        >
                          <span
                            className={classNames(
                              `bg-${tag.color}-600`,
                              "inline-flex flex-wrap grow-0 whitespace-nowrap items-center px-3 py-0.5 rounded-full text-sm text-white font-medium ml-0",
                            )}
                          >
                            {tag.name}
                          </span>
                        </button>
                      </div>
                    ))}
                  </div>
                  {resource.webLink ? (
                    <a href={resource.webLink} className="block mt-4">
                      <p className="text-xl font-semibold link-text">
                        {resource.name}
                      </p>
                    </a>
                  ) : (
                    <p className="text-xl font-semibold">{resource.name}</p>
                  )}
                  <p className="mt-3 text-base text-gray-500">
                    {resource.description}
                  </p>
                  <div className="mt-3 flex items-center">
                    <div className="flex flex-col">
                      <div className="flex">
                        {" "}
                        <p className="text-sm font-medium text-gray-900">
                          Authors: {resource.authors}
                        </p>
                      </div>
                    </div>
                  </div>
                  {resource.instructionsDownloadLink && (
                    <div className="mt-1 flex items-center">
                      <a
                        href={resource.instructionsDownloadLink}
                        className="block mt-4"
                      >
                        <p className="text-xl font-semibold link-text">
                          Download the instructions for this resource here.
                        </p>
                      </a>
                    </div>
                  )}
                  {resource.resourceDownloadLink && (
                    <div className="mt-1 flex items-center">
                      <a
                        href={resource.resourceDownloadLink}
                        className="block mt-4"
                      >
                        <p className="text-xl font-semibold link-text">
                          Download the resource here.
                        </p>
                      </a>
                    </div>
                  )}
                  {/* TODO: Add Contacts list */}
                </div>
              );
            })}
        </div>
      )}
      {!isFetching &&
        !isLoading &&
        !isError &&
        providerResources &&
        providerResources.length === 0 && (
          <h2 className="static-page-header mt-12">
            There are no resources to display.
          </h2>
        )}
      {isError && Sentry.captureException(error) && (
        <h2 className="static-page-header mt-12">
          There was an issue fetching resources. Our apologies - we're working
          on fixing the issue as quickly as possible.
        </h2>
      )}
    </section>
  );
};
