import React from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";

export type BannerProps = {
  dismissClick: () => void;
};

export const Banner: React.FC<BannerProps> = ({ dismissClick }) => {
  return (
    <div className="flex items-center gap-x-6 bg-indigo-600 px-6 py-4 sm:px-3.5 sm:before:flex-1">
      <p className="text-lg leading-6 text-white">
        <a
          href="https://www.sicklecellball.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          NY-Presbyterian's Dalio Center for Health Justice is launching a
          nationwide Sickle Cell awareness compaign with a custom basketball -
          find out more by clicking this banner!
        </a>
      </p>
      <div className="flex flex-1 justify-end">
        <button
          type="button"
          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
          onClick={dismissClick}
        >
          <span className="sr-only">Dismiss</span>
          <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};
