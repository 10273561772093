import React from "react";

import AgiosLogo from "../../assets/sponsor_logos/Agios_Logo.png";
import BeamLogo from "../../assets/sponsor_logos/Beam_Logo.jpg";
import BiolineLogo from "../../assets/sponsor_logos/Bioline_Logo.png";
import BluebirdLogo from "../../assets/sponsor_logos/Bluebird_Logo.png";
import BristolMeyersSquibbLogo from "../../assets/sponsor_logos/BristolMeyersSquibb_Logo.svg";
import ChiesiLogo from "../../assets/sponsor_logos/Chiesi_Logo.png";
import EditasLogo from "../../assets/sponsor_logos/Editas_Logo.png";
import FulcrumLogo from "../../assets/sponsor_logos/Fulcrum_Logo.png";
import LCLogo from "../../assets/sponsor_logos/LC_Logo.png";
import MedunikLogo from "../../assets/sponsor_logos/Medunik_Logo.jpg";
import NovartisLogo from "../../assets/sponsor_logos/Novartis_Logo.jpg";
import NovoNordiskLogo from "../../assets/sponsor_logos/NovoNordisk_Logo.jpg";
import PfizerLogo from "../../assets/sponsor_logos/Pfizer_Logo.png";
import VertexLogo from "../../assets/sponsor_logos/Vertex_Logo.png";
import { Link } from "react-router-dom";

export type LogoGridProps = {
  sponsorshipType: string;
};

export const LogoGrid = ({ sponsorshipType }: LogoGridProps) => {
  const logoInfo = [
    {
      type: "platinum",
      name: "Agios Pharmaceuticals",
      url: "https://www.agios.com/",
      logo: AgiosLogo,
    },
    {
      type: "platinum",
      name: "Bluebird Bio",
      url: "https://www.bluebirdbio.com/",
      logo: BluebirdLogo,
    },
    {
      type: "platinum",
      name: "Chiesi",
      url: "https://www.chiesi.com/en/",
      logo: ChiesiLogo,
    },
    {
      type: "platinum",
      name: "Editas Medicine",
      url: "https://www.editasmedicine.com/",
      logo: EditasLogo,
    },
    {
      type: "platinum",
      name: "Novo Nordisk",
      url: "https://www.novonordisk.com/",
      logo: NovoNordiskLogo,
    },
    {
      type: "platinum",
      name: "Pfizer",
      url: "https://www.pfizer.com/",
      logo: PfizerLogo,
    },
    {
      type: "gold",
      name: "Beam Therapeutics",
      url: "https://www.beamtx.com/",
      logo: BeamLogo,
    },
    {
      type: "gold",
      name: "Bioline",
      url: "https://biolinerx.com/",
      logo: BiolineLogo,
    },
    {
      type: "gold",
      name: "Bristol Meyers Squibb",
      url: "https://www.bms.com/",
      logo: BristolMeyersSquibbLogo,
    },
    {
      type: "gold",
      name: "Fulcrum Therapeutics",
      url: "https://www.fulcrumtx.com/",
      logo: FulcrumLogo,
    },
    {
      type: "gold",
      name: "Lourie, Chase, Forline, Carter & King",
      url: "https://www.cfcklaw.com/",
      logo: LCLogo,
    },
    {
      type: "gold",
      name: "Medunik USA",
      url: "https://www.medunikusa.com/",
      logo: MedunikLogo,
    },
    {
      type: "gold",
      name: "Novartis",
      url: "https://www.novartis.com/",
      logo: NovartisLogo,
    },
    {
      type: "gold",
      name: "Vertex Pharmaceuticals",
      url: "https://www.vrtx.com/",
      logo: VertexLogo,
    },
  ];

  return (
    <div className="bg-white pt-2">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="-mx-6 grid grid-cols-2 gap-0.5 overflow-hidden sm:mx-0 sm:rounded-2xl md:grid-cols-3">
          {logoInfo.map((logo) => {
            if (logo.type !== sponsorshipType) {
              return null;
            } else {
              return (
                <div className="bg-gray-300/10 p-8 sm:p-10">
                  <Link to={logo.url} className="block">
                    <div
                      className={`bg-white py-6 rounded-3xl ${
                        logo.name === "Lourie, Chase, Forline, Carter & King"
                          ? "max-h-60"
                          : "max-h-64"
                      }`}
                    >
                      <img
                        className={` ${
                          logo.name === "Novo Nordisk" ||
                          logo.name === "Vertex Pharmaceuticals"
                            ? "max-h-20"
                            : logo.name ===
                              "Lourie, Chase, Forline, Carter & King"
                            ? "max-h-32"
                            : "max-h-36"
                        } w-full object-contain`}
                        src={logo.logo}
                        alt={logo.name + " logo"}
                      />
                    </div>
                  </Link>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};
